exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-angebot-akustik-und-schwingungen-de-js": () => import("./../../../src/pages/angebot/akustik-und-schwingungen.de.js" /* webpackChunkName: "component---src-pages-angebot-akustik-und-schwingungen-de-js" */),
  "component---src-pages-angebot-android-automotive-de-js": () => import("./../../../src/pages/angebot/android-automotive.de.js" /* webpackChunkName: "component---src-pages-angebot-android-automotive-de-js" */),
  "component---src-pages-angebot-de-js": () => import("./../../../src/pages/angebot.de.js" /* webpackChunkName: "component---src-pages-angebot-de-js" */),
  "component---src-pages-certificates-en-js": () => import("./../../../src/pages/certificates.en.js" /* webpackChunkName: "component---src-pages-certificates-en-js" */),
  "component---src-pages-datenschutz-de-js": () => import("./../../../src/pages/datenschutz.de.js" /* webpackChunkName: "component---src-pages-datenschutz-de-js" */),
  "component---src-pages-haftungsausschluss-de-js": () => import("./../../../src/pages/haftungsausschluss.de.js" /* webpackChunkName: "component---src-pages-haftungsausschluss-de-js" */),
  "component---src-pages-impressum-de-js": () => import("./../../../src/pages/impressum.de.js" /* webpackChunkName: "component---src-pages-impressum-de-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-module-de-js": () => import("./../../../src/pages/module.de.js" /* webpackChunkName: "component---src-pages-module-de-js" */),
  "component---src-pages-produkte-de-js": () => import("./../../../src/pages/produkte.de.js" /* webpackChunkName: "component---src-pages-produkte-de-js" */),
  "component---src-pages-produkte-prueffinger-de-js": () => import("./../../../src/pages/produkte/prueffinger.de.js" /* webpackChunkName: "component---src-pages-produkte-prueffinger-de-js" */),
  "component---src-pages-produkte-simulationsbox-de-js": () => import("./../../../src/pages/produkte/simulationsbox.de.js" /* webpackChunkName: "component---src-pages-produkte-simulationsbox-de-js" */),
  "component---src-pages-story-door-control-module-en-js": () => import("./../../../src/pages/story/door-control-module.en.js" /* webpackChunkName: "component---src-pages-story-door-control-module-en-js" */),
  "component---src-pages-story-ev-charger-en-js": () => import("./../../../src/pages/story/ev-charger.en.js" /* webpackChunkName: "component---src-pages-story-ev-charger-en-js" */),
  "component---src-pages-story-multifunktions-touchlenkrad-de-js": () => import("./../../../src/pages/story/multifunktions-touchlenkrad.de.js" /* webpackChunkName: "component---src-pages-story-multifunktions-touchlenkrad-de-js" */),
  "component---src-pages-story-test-finger-en-js": () => import("./../../../src/pages/story/test-finger.en.js" /* webpackChunkName: "component---src-pages-story-test-finger-en-js" */),
  "component---src-pages-story-touch-steering-wheel-en-js": () => import("./../../../src/pages/story/touch-steering-wheel.en.js" /* webpackChunkName: "component---src-pages-story-touch-steering-wheel-en-js" */),
  "component---src-pages-story-tuerbedienmodul-de-js": () => import("./../../../src/pages/story/tuerbedienmodul.de.js" /* webpackChunkName: "component---src-pages-story-tuerbedienmodul-de-js" */),
  "component---src-pages-story-wallbox-de-js": () => import("./../../../src/pages/story/wallbox.de.js" /* webpackChunkName: "component---src-pages-story-wallbox-de-js" */),
  "component---src-pages-zertifikate-de-js": () => import("./../../../src/pages/zertifikate.de.js" /* webpackChunkName: "component---src-pages-zertifikate-de-js" */)
}

